/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.noborder {
  border: none !important;
}

.otherCapcha {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
.otherCapcha .captchaImg {
  display: inline-block;
}
.otherCapcha .captcha_field {
  width: 150px;
  display: inline-block;
}
.curPointer {
  cursor: pointer;
}
.refreshIcon {
  margin: 0 5px;
}

@media (max-width: 575.98px) {
  .otherCapcha .captcha_field {
    width: 114px;
  }
}
